import styled from "styled-components";

export const RevokeButton = styled.p`
    color: #b71c1c;
    margin: 0;

    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`