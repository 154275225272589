import styled from "styled-components"
import { Strings } from "../../Strings"

const Wrapper = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Title = styled.p`
    font-weight: 700;
    margin: 0;
    margin-bottom: 5px;
`
const Message = styled.p`
    margin: 0;
    margin-top: 5px;
`

export const DashboardError: React.FC = () => {
    return (
        <Wrapper>
            <Title>{ Strings.dashboardPage.loadingFailed.title }</Title>
            <Message>{ Strings.dashboardPage.loadingFailed.message }</Message>
        </Wrapper>
    )
}