import styled from "styled-components"
import { Strings } from "../../../Strings"

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const AccessKeysError: React.FC = () => {
    return (
        <Wrapper>
            <p>{ Strings.accessKeysPage.error }</p>
        </Wrapper>
    )
}