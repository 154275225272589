import styled from "styled-components"
import { FlexSpacer } from "../../../components/styled/FlexSpacer"
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Strings } from "../../../Strings";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

const Title = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 1.5rem;
`

export interface AccessKeysHeaderProps {
    dismissAction: () => void,
}

export const AccessKeysHeader: React.FC<AccessKeysHeaderProps> = (props: AccessKeysHeaderProps) => {
    return (
        <Wrapper>
            <Title>{ Strings.accessKeysPage.title }</Title>
            <FlexSpacer />
            <IconButton
                onClick={props.dismissAction}
            >
                <CloseIcon />
            </IconButton>
        </Wrapper>
    )
}