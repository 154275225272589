import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorDialog, ErrorDialogContent } from "../../components/ErrorDialog";
import { TopMenu } from "../../components/TopMenu";
import { BusinessLicense, useGenerateLicensesMutation, usePartnerWithLicensesQuery, useRevokeLicenseMutation, useSetLicenseNoteMutation } from "../../generated/graphql";
import { useAvailableLicensesIndicatorData } from "../../hooks/UseAvailableLicensesIndicatorData";
import { PartnerToAccountTransformer } from "../../models/transformers/PartnerToAccountTransformer";
import { updateAccount } from "../../reducers/AccountReducer";
import { RootState } from "../../Store";
import { Strings } from "../../Strings";
import { ClientNoteDialog } from "./dialogs/ClientNoteDialog";
import { DashboardData } from "./DashboardData";
import { DashboardError } from "./DashboardError";
import { DashboardLoading } from "./DashboardLoading";
import { GenerateLicenseDialog } from "./dialogs/GenerateLicenseDialog";
import { RevokeLicenseDialog } from "./dialogs/RevokeLicenseDialog";
import { Modal } from "@mui/material";
import { AccessKeysPage } from "../access_keys/AccessKeysPage";

enum DashboardState {
    loading,
    error,
    data,
}

export const DashboardPage: React.FC = () => {

    const [state, setState] = useState(DashboardState.loading);
    const fetchQuery = usePartnerWithLicensesQuery();
    const [dialogError, setDialogError] = useState(null as ErrorDialogContent | null);
    const storedUser = useSelector((state: RootState) => state.accountReducer?.account);
    const dispatch = useDispatch();
    
    const { nonRevokedLicenses, licenseLimit } = useAvailableLicensesIndicatorData(fetchQuery.data?.businessPartner?.licenses ?? []);
    const [generateLicense, generateLicenseMutation] = useGenerateLicensesMutation();
    const [generateLicenseIsShown, setGenerateLicenseIsShown] = useState(false);
    const [generateLicenseCount, setGenerateLicenseCount] = useState(1);

    const [pendingRevokeLicense, setPendingRevokeLicense] = useState(null as null | BusinessLicense);
    const [revokeLicense, revokeLicenseMutation] = useRevokeLicenseMutation();

    const [pendingNoteLicenseKey, setPendingNoteLicenseKey] = useState(null as null | string);
    const [pendingNoteContent, setPendingNoteContent] = useState(null as null | string);
    const [setNote, setNoteMutation] = useSetLicenseNoteMutation();

    const [accessKeysModalIsShown, setAccessKeysModealIsShown] = useState(false);

    if (!generateLicenseMutation.loading && !revokeLicenseMutation.loading && !setNoteMutation.loading) {
        if (fetchQuery.data?.businessPartner && state !== DashboardState.data) {
            setState(DashboardState.data);
        } else if (fetchQuery.loading && state !== DashboardState.loading) {
            setState(DashboardState.loading);
        } else if ((fetchQuery.error || (!fetchQuery.data?.businessPartner && !fetchQuery.loading)) && state !== DashboardState.error) {
            setState(DashboardState.error);
        }
    }

    useEffect(() => {
        if (storedUser && fetchQuery.data?.businessPartner) {
            const partner = PartnerToAccountTransformer.transform(fetchQuery.data.businessPartner);
            if (JSON.stringify(storedUser) !== JSON.stringify(partner)) {
                dispatch(
                    updateAccount(partner)
                )
            }
        }
    }, [dispatch, storedUser, fetchQuery.data?.businessPartner])


    // License generate

    function generateLicenseClicked() {
        setGenerateLicenseCount(1);
        setGenerateLicenseIsShown(true);
    }

    function generateLicenses() {
        setGenerateLicenseIsShown(false);

        if (generateLicenseCount <= 0 || !Number.isInteger(generateLicenseCount)) {
            setDialogError(Strings.dashboardPage.errors.invalidLicenseCount);
            return;
        }

        if (nonRevokedLicenses + generateLicenseCount > licenseLimit) {
            setDialogError(Strings.dashboardPage.errors.insufficientLicenseLimit);
            return;
        }

        setState(DashboardState.loading);
        generateLicense({
            variables: {
                numberOfLicenses: generateLicenseCount,
            }
        })
            .catch(() => {
                setState(DashboardState.error)
            })
            .then(() => {
                fetchQuery.refetch();
            });
    }


    // License revoke

    function revokeButtonClicked(license: BusinessLicense) {
        setPendingRevokeLicense(license);
    }

    function revokeLicenseConfirmed() {
        if (pendingRevokeLicense) {
            const licenseKey = pendingRevokeLicense.licenseKey;
            setPendingRevokeLicense(null);
            setState(DashboardState.loading);
            revokeLicense({
                variables: {
                    licenseKey: licenseKey,
                }
            })
                .catch(() => {
                    setState(DashboardState.error)
                })
                .then(() => {
                    fetchQuery.refetch();
                });
        }
    }


    // License note

    function noteButtonClicked(license: BusinessLicense) {
        setPendingNoteContent(license.note);
        setPendingNoteLicenseKey(license.licenseKey);
    }

    function savePendingClientNote() {
        if (pendingNoteContent) {
            const newNote = pendingNoteContent;
            setPendingNoteContent(null);
            setState(DashboardState.loading);
            setNote({
                variables: {
                    licenseKey: pendingNoteLicenseKey ?? '',
                    note: newNote,
                }
            })
                .catch(() => {
                    setState(DashboardState.error)
                })
                .then(() => {
                    fetchQuery.refetch();
                });
        }
    }


    // Render

    const content = () => {
        switch (state) {
            case DashboardState.data:
                return <DashboardData
                    licenses={fetchQuery.data?.businessPartner?.licenses ?? []}
                    generateLicenseClicked={generateLicenseClicked}
                    accessKeysClicked={() => setAccessKeysModealIsShown(true)}
                    revokeButtonClicked={revokeButtonClicked}
                    noteButtonClicked={noteButtonClicked}
                />
            case DashboardState.error:
                return <DashboardError />
            case DashboardState.loading:
                return <DashboardLoading />
        }
    }

    return (
        <>
            <TopMenu />
            { content() }
            <GenerateLicenseDialog
                isShown={generateLicenseIsShown}
                numberOfLicenses={generateLicenseCount}
                setNumberOfLicenses={setGenerateLicenseCount}
                dismissAction={() => setGenerateLicenseIsShown(false)}
                generateAction={generateLicenses}
            />
            <RevokeLicenseDialog
                licenseToRevoke={pendingRevokeLicense}
                dismissAction={() => setPendingRevokeLicense(null)}
                revokeLicenseAction={revokeLicenseConfirmed}
            />
            <ClientNoteDialog
                note={pendingNoteContent}
                setNote={setPendingNoteContent}
                dismissAction={() => setPendingNoteContent(null)}
                saveAction={savePendingClientNote}
            />
            <ErrorDialog 
                content={dialogError}
                okButtonClicked={() => setDialogError(null)}
            />
            <Modal
                open={accessKeysModalIsShown}
                onClose={() => setAccessKeysModealIsShown(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <AccessKeysPage
                        isShown={accessKeysModalIsShown}
                        dismissAction={() => setAccessKeysModealIsShown(false)}
                    />
                </div>
            </Modal>
        </>
    )
}