import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Strings } from '../../../Strings';

export interface GenerateLicenseDialogProps {
    isShown: boolean,
    numberOfLicenses: number,
    setNumberOfLicenses: (value: number) => void,
    dismissAction: () => void,
    generateAction: () => void,
}

export const GenerateLicenseDialog: React.FC<GenerateLicenseDialogProps> = (props: GenerateLicenseDialogProps) => (
    <Dialog open={props.isShown} onClose={props.dismissAction} fullWidth={true}>
        <DialogTitle>{ Strings.dashboardPage.generateLicenseDialog.title }</DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label={ Strings.dashboardPage.generateLicenseDialog.numberOfLicenses }
                type="number"
                fullWidth
                variant="standard"
                value={props.numberOfLicenses}
                onChange={(event) => props.setNumberOfLicenses(parseFloat(event.target.value) ?? 0)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.dismissAction}>{ Strings.common.cancel }</Button>
            <Button onClick={props.generateAction}>{ Strings.dashboardPage.generateLicenseDialog.generateButton }</Button>
        </DialogActions>
    </Dialog>
);