import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Strings } from '../../../Strings';
import { useState } from 'react';

export interface ClientNoteDialogProps {
    note: string | null,
    setNote: (note: string) => void,
    dismissAction: () => void,
    saveAction: () => void,
}

export const ClientNoteDialog: React.FC<ClientNoteDialogProps> = (props: ClientNoteDialogProps) => {

    const [note, setNote] = useState(props.note);
    if (props.note !== null && props.note !== note) {
        setNote(props.note);
    }

    return (
        <Dialog open={props.note !== null} onClose={props.dismissAction} fullWidth={true}>
            <DialogTitle>{ Strings.dashboardPage.clientNoteDialog.title }</DialogTitle>
            <DialogContent>
                <TextField
                autoFocus
                label={ Strings.dashboardPage.clientNoteDialog.placeholder }
                fullWidth
                variant="standard"
                value={note}
                onChange={(event) => props.setNote(event.target.value ?? '')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.dismissAction}>{ Strings.common.cancel }</Button>
                <Button onClick={props.saveAction}>{ Strings.dashboardPage.clientNoteDialog.saveButton }</Button>
            </DialogActions>
        </Dialog>
    );
}