import { configureStore } from '@reduxjs/toolkit';
import AccountReducer from './reducers/AccountReducer';

export const reduxStore = configureStore({ 
  reducer: {
    accountReducer: AccountReducer,
  },
  devTools: false
});

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch