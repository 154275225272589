import { Divider } from "@mui/material"
import styled from "styled-components"
import { BusinessLicense } from "../../generated/graphql"
import { DashboardDataHeader } from "./compontents/DashboardDataHeader"
import { LicenseList } from "./compontents/LicenseList"

const Wrapper = styled.div`
    margin: 20px;
    padding-bottom: 20px;
`
export interface DashboardDataProps {
    licenses: BusinessLicense[],
    generateLicenseClicked: () => void,
    accessKeysClicked: () => void,
    revokeButtonClicked: (license: BusinessLicense) => void,
    noteButtonClicked: (license: BusinessLicense) => void,
}

export const DashboardData: React.FC<DashboardDataProps> = (props: DashboardDataProps) => {
    return (
        <Wrapper>
            <DashboardDataHeader 
                licenses={props.licenses}
                generateLicenseClicked={props.generateLicenseClicked}
                accessKeysClicked={props.accessKeysClicked}
            />
            <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
            <LicenseList 
                licenses={props.licenses}
                revokeButtonClicked={props.revokeButtonClicked}
                noteButtonClicked={props.noteButtonClicked}
            />
        </Wrapper>
    )
}