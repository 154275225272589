import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { Strings } from "../../../Strings";

export interface AccessKeyCreatedDialogProps {
    partnerAccessKey: string | null,
    dismissAction: () => void,
}

export const AccessKeyCreatedDialog: React.FC<AccessKeyCreatedDialogProps> = (props: AccessKeyCreatedDialogProps) => {
    const [accessKey, setAccessKey] = useState('');
    const propsAccessKey = props.partnerAccessKey ?? '';
    if (accessKey !== propsAccessKey && propsAccessKey !== '') {
        setAccessKey(propsAccessKey);
    }

    return (
        <Dialog
            open={props.partnerAccessKey !== null}
            onClose={props.dismissAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                { Strings.accessKeysPage.keyCreatedDialog.title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { Strings.accessKeysPage.keyCreatedDialog.message(accessKey) }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.dismissAction}>{ Strings.common.ok }</Button>
            </DialogActions>
        </Dialog>
    );
}