import { CircularProgress } from "@mui/material"
import styled from "styled-components"

const Wrapper = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const DashboardLoading: React.FC = () => {
    return (
        <Wrapper>
            <CircularProgress />
        </Wrapper>
    )
}