import { Button, TextField } from '@mui/material'
import { useDispatch } from "react-redux"
import { useState } from 'react'
import { ErrorDialog, ErrorDialogContent } from '../../components/ErrorDialog'
import { useLoginLazyQuery } from '../../generated/graphql'
import { Strings } from '../../Strings'
import styled from 'styled-components'
import { login } from '../../reducers/AccountReducer'
import { PartnerToAccountTransformer } from '../../models/transformers/PartnerToAccountTransformer'

const Container = styled.div`
  display: flex;
  height: 100%;
`

const ImagePanel = styled.div`
    flex: 1;
    background: rgba(72,160,194,1);
    background-image: url('/images/login_background.jpg');
    background-size: cover;
    background-position: center center;

    @media (max-width: 768px) {
        visibility: none;
    }
`

const LoginPanel = styled.div`
    width: 400px;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.19); 
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
    }
`
const LoginContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TitleLabel = styled.h1`
    font-size: 1.5rem;
    margin-bottom: 10px;
    margin: 0px 20px 10px 20px;

    @media (max-width: 768px) {
        margin-top: 50px;
    }
`

const LoginButtonWrapper = styled.div`
    margin: 10px 20px 0 20px;
`

enum LoginState {
    fillInInformation,
    performingLogin,
}

export const LoginPage: React.FC = () => {

    const dispatch = useDispatch();
    const [state, setState] = useState(LoginState.fillInInformation);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dialogError, setDialogError] = useState(null as ErrorDialogContent | null);

    const [performLoginQuery, { data }] = useLoginLazyQuery();

    function loginButtonClicked() {
        if (email.length === 0 || password.length === 0) {
            setDialogError(Strings.loginPage.errors.missingEmailOrPassword);
        } else {
            setState(LoginState.performingLogin);
            performLoginQuery({
                variables: {
                    email: email,
                    password: password,
                },
            });
        }
    }

    if (data && !data.businessPartnerLogin && state === LoginState.performingLogin) {
        setState(LoginState.fillInInformation);
        setDialogError(Strings.loginPage.errors.incorrectCredentials);
    }

    if (data?.businessPartnerLogin) {
        dispatch(login([
            PartnerToAccountTransformer.transform(data.businessPartnerLogin.partner),
            data.businessPartnerLogin.apiKey,
        ]));
    }

    return (
        <Container>
            <ImagePanel />
            <LoginPanel>
                <LoginContentWrapper>
                    <TitleLabel>HealthExport Business</TitleLabel>
                    <TextField 
                        variant='outlined'
                        label={Strings.loginPage.email}
                        type='email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        sx={{
                            margin: '5px 20px 5px 20px'
                        }}
                    />
                    <TextField 
                        variant='outlined'
                        label={Strings.loginPage.password}
                        type='password'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        sx={{
                            margin: '5px 20px 5px 20px'
                        }}
                    />
                    <LoginButtonWrapper>
                        <Button 
                            variant="contained"
                            onClick={loginButtonClicked}
                            disabled={state === LoginState.performingLogin}
                        >{ state === LoginState.performingLogin 
                                ? Strings.loginPage.loggingInButton 
                                : Strings.loginPage.loginButton
                        }</Button>
                    </LoginButtonWrapper>
                </LoginContentWrapper>
            </LoginPanel>
            
            <ErrorDialog 
                content={dialogError}
                okButtonClicked={() => setDialogError(null)}
            />
        </Container>
    )
}