import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { BusinessPartnerAccessKey } from "../../../generated/graphql";
import { Strings } from "../../../Strings";

export interface RevokeAccessKeyDialogProps {
    keyToRevoke: BusinessPartnerAccessKey | null,
    revokeAction: () => void,
    dismissAction: () => void,
}

export const RevokeAccessKeyDialog: React.FC<RevokeAccessKeyDialogProps> = (props: RevokeAccessKeyDialogProps) => {
    const [name, setName] = useState('');
    const propsName = props.keyToRevoke?.name ?? '';
    if (name !== propsName && propsName !== '') {
        setName(propsName);
    }

    return (
        <Dialog
            open={props.keyToRevoke !== null}
            onClose={props.dismissAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                { Strings.accessKeysPage.revokeKeyDialog.title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { Strings.accessKeysPage.revokeKeyDialog.message(name) }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.dismissAction}>{ Strings.common.cancel }</Button>
                <Button onClick={props.revokeAction}>{ Strings.accessKeysPage.revokeKeyDialog.revokeButton }</Button>
            </DialogActions>
        </Dialog>
    );
}