import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { BusinessLicense } from "../../../generated/graphql";
import { Strings } from "../../../Strings";

export interface RevokeLicenseDialogProps {
    licenseToRevoke: BusinessLicense | null,
    revokeLicenseAction: () => void,
    dismissAction: () => void,
}

export const RevokeLicenseDialog: React.FC<RevokeLicenseDialogProps> = (props: RevokeLicenseDialogProps) => {
    const [licenseKey, setLicenseKey] = useState('');
    const propsLicenseKey = props.licenseToRevoke?.licenseKey ?? '';
    if (licenseKey !== propsLicenseKey && propsLicenseKey !== '') {
        setLicenseKey(propsLicenseKey);
    }

    return (
        <Dialog
            open={props.licenseToRevoke !== null}
            onClose={props.dismissAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                { Strings.dashboardPage.revokeLicenseDialog.title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { Strings.dashboardPage.revokeLicenseDialog.message(licenseKey) }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.dismissAction}>{ Strings.common.cancel }</Button>
                <Button onClick={props.revokeLicenseAction}>{ Strings.dashboardPage.revokeLicenseDialog.revokeButton }</Button>
            </DialogActions>
        </Dialog>
    );
}