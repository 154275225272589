import { createTheme, ThemeProvider } from "@mui/material";
import { createGlobalStyle } from 'styled-components'
import { Colors } from "../models/Colors";

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    height: 100%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
  }
`

export interface StylingProviderProps {
    children: React.ReactNode
}

export const StylingProvider: React.FC<StylingProviderProps> = (props: StylingProviderProps) => {

    const muiTheme = createTheme({
        palette: {
            primary: {
                main: Colors.primary,
                contrastText: '#ffffff'
            },
            secondary: {
                main: '#484747',
                contrastText: '#ffffff'
            },
            // @ts-ignore
            whiteAllTheWay: {
                light: '#ffffff',
                main: '#ffffff',
                dark: 'rgba(0, 0, 0, 0.87)',
                contrastText: 'rgba(0, 0, 0, 0.87)',
            }
        },
    });

    return (
        <ThemeProvider theme={muiTheme}>
            <GlobalStyle />
            { props.children }
        </ThemeProvider>
    )
}