import { Button } from "@mui/material";
import styled from "styled-components";
import { FlexSpacer } from "../../../components/styled/FlexSpacer";
import { BusinessLicense } from "../../../generated/graphql"
import { useAvailableLicensesIndicatorData } from "../../../hooks/UseAvailableLicensesIndicatorData";
import { Strings } from "../../../Strings";
import { AvailableLicensesIndicator } from "./AvailableLicensesIndicator"

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

const ButtonWrapper = styled.div`
    margin-left: 20px;
`

export interface DashboardDataHeaderProps {
    licenses: BusinessLicense[],
    accessKeysClicked: () => void,
    generateLicenseClicked: () => void,
}

export const DashboardDataHeader: React.FC<DashboardDataHeaderProps> = (props: DashboardDataHeaderProps) => {

    const licenseIndicatorData = useAvailableLicensesIndicatorData(props.licenses);

    return (
        <Wrapper>
            <div>
                <AvailableLicensesIndicator
                    nonRevokedLicenses={licenseIndicatorData.nonRevokedLicenses}
                    licenseLimit={licenseIndicatorData.licenseLimit}
                />
            </div>
            <FlexSpacer />
            <ButtonWrapper>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={props.generateLicenseClicked}
                    size="small"
                >{ Strings.dashboardPage.generateLicenseButton }</Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={props.accessKeysClicked}
                    size="small"
                    sx={{ marginLeft: '20px' }}
                >{ Strings.dashboardPage.accessKeysButton }</Button>
            </ButtonWrapper>
        </Wrapper>
    )
}