import { CircularProgress } from "@mui/material"
import styled from "styled-components"
import { Strings } from "../../../Strings"

export interface AvailableLicensesIndicatorProps {
    nonRevokedLicenses: number,
    licenseLimit: number,
}

const Title = styled.p`
    margin: 0;
    margin-bottom: 3px;
    font-size: 0.9rem;
`

const Value = styled.p`
    margin: 0;
    margin-top: 3px;
    font-weight: 700;
`

const ValueWrapper = styled.div`
    display: flex;
    align-items: center;
`

const ChartsWrapper = styled.div`
    display: grid;
    margin-left: 10px;

    * {
        grid-row-start: 1;
        grid-column-start: 1;
    }
`

export const AvailableLicensesIndicator: React.FC<AvailableLicensesIndicatorProps> = (props: AvailableLicensesIndicatorProps) => {
    return (
        <>
            <Title>{ Strings.dashboardPage.availableLicensesIndicator.title }</Title>
            <ValueWrapper>
                <Value>{ props.nonRevokedLicenses } / { props.licenseLimit }</Value>
                <ChartsWrapper>
                    <CircularProgress
                        variant="determinate"
                        size={25}
                        thickness={6}
                        sx={{
                            opacity: '0.3'
                        }}
                        value={100}
                    />
                    <CircularProgress
                        variant="determinate"
                        size={25}
                        thickness={6}
                        value={props.nonRevokedLicenses / props.licenseLimit * 100}
                    />
                </ChartsWrapper>
            </ValueWrapper>
        </>
    )
}