import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '../entities/Account';
import { LocalStorageManager } from '../models/LocalStorageManager';

interface AccountSliceData {
    account?: Account,
    apiKey?: string,
}

function makeDefaultAccountSlice(): AccountSliceData | null {
    return {
        account: LocalStorageManager.getAccount() ?? undefined,
        apiKey: LocalStorageManager.getApiKey() ?? undefined,
    }
}

export const accountSlice = createSlice({
    name: 'account',
    initialState: makeDefaultAccountSlice() as AccountSliceData | null,
    reducers: {
        login: (state, action: PayloadAction<[Account, string]>) => {
            if (state) {
                LocalStorageManager.setAccount(action.payload[0]);
                LocalStorageManager.setApiKey(action.payload[1]);
                state.account = action.payload[0];
                state.apiKey = action.payload[1];
            }
        },
        logout: state => {
            if (state) {
                LocalStorageManager.setAccount(null);
                LocalStorageManager.setApiKey(null);
                state.account = undefined;
                state.apiKey = undefined;
            }
        },
        updateAccount: (state, action: PayloadAction<Account>) => {
            if (state) {
                LocalStorageManager.setAccount(action.payload);
                state.account = action.payload;
            }
        },
    }
});

export const { login, logout, updateAccount } = accountSlice.actions;

export default accountSlice.reducer;