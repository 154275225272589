import styled from "styled-components"
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reducers/AccountReducer";
import { RootState } from "../Store";
import { Colors } from "../models/Colors";
import { FlexSpacer } from "./styled/FlexSpacer";

const Wrapper = styled.div`
    background-color: ${Colors.primary};
    padding: 12px 20px;
    display: flex;
    align-items: center;

    p {
        color: white;
    }
`

const TitleTopLabel = styled.p`
    margin: 0 0 1px 0;
    font-size: 1.1rem;
    opacity: 0.9;
`
const TitleBottomLabel = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 1.2rem;
`
const PartnerNameLabel = styled.p`
    margin: 0;
    margin-right: 10px;
    opacity: 0.9;

    @media (max-width: 500px) {
        display: none;
    }
`

export const TopMenu: React.FC = () => {

    const dispatch = useDispatch();
    const partnerName = useSelector((state: RootState) => state.accountReducer?.account?.name ?? '');

    return (
        <Wrapper>
            <div>
                <TitleTopLabel>HealthExport</TitleTopLabel>
                <TitleBottomLabel>Business</TitleBottomLabel>
            </div>
            <FlexSpacer />
            <PartnerNameLabel>{ partnerName }</PartnerNameLabel>
            <IconButton
                // @ts-ignore
                color="whiteAllTheWay"
                onClick={() => { dispatch(logout()) }}
            >
                <LogoutIcon />
            </IconButton>
        </Wrapper>
    )
}