import { useSelector } from 'react-redux';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { LoginPage } from './pages/login/LoginPage';
import { RootState } from './Store';

function App() {

  const isLoggedIn = useSelector((state: RootState) => state.accountReducer?.apiKey !== undefined);

  return (
    <>
      { !isLoggedIn &&
        <LoginPage />
      }
      { isLoggedIn &&
        <DashboardPage />
      }
    </>
  );
}

export default App;
