import { useSelector } from "react-redux";
import { BusinessLicense, BusinessLicenseState } from "../generated/graphql";
import { RootState } from "../Store";

export type AvailableLicensesIndicatorData = {
    nonRevokedLicenses: number,
    licenseLimit: number,
}

export function useAvailableLicensesIndicatorData(licenses: BusinessLicense[]): AvailableLicensesIndicatorData {
    const licenseLimit = useSelector((state: RootState) => state.accountReducer?.account?.licenseLimit) ?? 0;
    const nonRevokedLicenses = licenses
        .filter(license => (license.state === BusinessLicenseState.WaitingForActivation || license.state === BusinessLicenseState.Active))
        .length
    return {
        nonRevokedLicenses: nonRevokedLicenses,
        licenseLimit: licenseLimit,
    }
}