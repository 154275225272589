import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from '@apollo/client/link/context';
import { StylingProvider } from './providers/StylingProvider';
import { Provider } from 'react-redux';
import { reduxStore } from './Store';
import { LocalStorageManager } from './models/LocalStorageManager';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      apitoken: LocalStorageManager.getApiKey() ?? '',
    }
  }
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(new BatchHttpLink({
    uri: process.env.REACT_APP_DELTA_URL,
  })),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    }
  },
});


root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <StylingProvider>
        <Provider store={reduxStore}>
          <App />
        </Provider>
      </StylingProvider>
    </ApolloProvider>
  </React.StrictMode>
);