import { Account } from "../entities/Account";

const localStoragePrefix = 'HER_BUSINESS_';

export enum LocalStorageDataType {
    account = 'account',
    apiKey = 'apiKey',
}

export type LocalStorageData = 
    {
        type: LocalStorageDataType.account,
        value?: Account,
    } | 
    {
        type: LocalStorageDataType.apiKey,
        value?: string,
    }

function set(value: LocalStorageData) {
    const key = localStoragePrefix + value.type;
    if (value.value) {
        localStorage.setItem(key, JSON.stringify(value.value));
    } else {
        localStorage.removeItem(key);
    }
}

function get<T>(key: LocalStorageDataType): T | null {
    const encodedValue = localStorage.getItem(localStoragePrefix + key);
    if (encodedValue && encodedValue !== null && encodedValue.length > 0) {
        return JSON.parse(encodedValue) as T;
    } else {
        return null;
    }
}

export const LocalStorageManager = {
    clear() {
        localStorage.clear()
    },

    setAccount(account: Account | null) {
        set({
            type: LocalStorageDataType.account,
            value: account ?? undefined,
        })
    },
    getAccount(): Account | null { return get<Account>(LocalStorageDataType.account) },

    setApiKey(apiKey: string | null) {
        set({
            type: LocalStorageDataType.apiKey,
            value: apiKey ?? undefined,
        })
    },
    getApiKey(): string | null { return get<string>(LocalStorageDataType.apiKey) },
};