import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { Strings } from "../Strings";

export type ErrorDialogContent = {
    title: string,
    message: string,
}

export interface ErrorDialogProps {
    content: ErrorDialogContent | null,
    okButtonClicked: () => void,
}

export const ErrorDialog: React.FC<ErrorDialogProps> = (props: ErrorDialogProps) => {
    
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    if (props.content && (props.content.title !== title || props.content.message !== message)) {
        setTitle(props.content.title);
        setMessage(props.content.message);
    }

    return (
        <Dialog
            open={props.content !== null}
            onClose={props.okButtonClicked}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                { title }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.okButtonClicked} autoFocus>{ Strings.common.ok }</Button>
            </DialogActions>
        </Dialog>
    );
}