import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import styled from "styled-components"
import { RevokeButton } from "../../../components/styled/RevokeButton"
import { BusinessPartnerAccessKey } from "../../../generated/graphql"
import { Strings } from "../../../Strings"
import { AccessKeysNewKeyForm } from "../components/AccessKeysNewKeyForm"

const SectionTitle = styled.p`
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 40px;
`
export interface AccessKeysDataProps {
    accessKeys: BusinessPartnerAccessKey[],
    pedningAccessKeyName: string,
    addButtonIsEnabled: boolean,
    setPendingAccessKey: (value: string) => void,
    addButtonClicked: () => void,
    revokeButtonClicked: (accessKey: BusinessPartnerAccessKey) => void,
}

export const AccessKeysData: React.FC<AccessKeysDataProps> = (props: AccessKeysDataProps) => {
    return (
        <>
            <AccessKeysNewKeyForm
                pedningAccessKeyName={props.pedningAccessKeyName}
                addButtonIsEnabled={props.addButtonIsEnabled}
                setPendingAccessKey={props.setPendingAccessKey}
                addButtonClicked={props.addButtonClicked}
            />
            { props.accessKeys.length > 0 &&
                <>
                    <SectionTitle>{ Strings.accessKeysPage.accessKeysSection }</SectionTitle>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { props.accessKeys.map((key) => (
                                    <TableRow
                                        key={key.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{key.name}</TableCell>
                                        <TableCell align="right">
                                            <RevokeButton
                                                    onClick={() => props.revokeButtonClicked(key)}
                                            >Revoke</RevokeButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </>
    )
}