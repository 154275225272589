import { Account } from "../../entities/Account";
import { PartnerFragment } from "../../generated/graphql";

export const PartnerToAccountTransformer = {
    transform(partner: PartnerFragment): Account {
        return {
            name: partner.name,
            email: partner.email,
            activeLicenseCount: partner.activeLicenseCount,
            licenseLimit: partner.licenseLimit,
        }
    }
}