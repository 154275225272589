import { Button } from "@mui/material"
import styled from "styled-components"
import { Strings } from "../../../Strings"

const Wrapper = styled.div`
    display: flex;
    margin-top: 20px;
`

const apiDocsLink = 'https://remoteapi.healthexport.app/api/v2/docs/'
const remoteLink = 'https://remote.healthexport.app/'

export const AccessKeysLinks: React.FC = () => {

    function apiDocumentationButtonClicked() {
        window.open(apiDocsLink, "_blank")
    }

    function remoteWebsiteButtonClicked() {
        window.open(remoteLink, "_blank")
    }

    return (
        <Wrapper>
            <Button
                    color="secondary"
                    variant="contained"
                    onClick={apiDocumentationButtonClicked}
                    size="small"
                >{ Strings.accessKeysPage.linkButtons.apiDocs }</Button>
            <Button
                    color="secondary"
                    variant="contained"
                    onClick={remoteWebsiteButtonClicked}
                    size="small"
                    sx={{
                        marginLeft: '20px'
                    }}
                >{ Strings.accessKeysPage.linkButtons.remoteWebsite }</Button>
        </Wrapper>
    )
}