import { Button, TextField } from "@mui/material"
import styled from "styled-components"
import { Strings } from "../../../Strings"

const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
`

const SectionTitle = styled.p`
    font-weight: 700;
    margin: 0;
`

export interface AccessKeysNewKeyFormProps {
    pedningAccessKeyName: string,
    addButtonIsEnabled: boolean,
    setPendingAccessKey: (value: string) => void,
    addButtonClicked: () => void,
}

export const AccessKeysNewKeyForm: React.FC<AccessKeysNewKeyFormProps> = (props: AccessKeysNewKeyFormProps) => {
    return (
        <>
            <SectionTitle>{ Strings.accessKeysPage.createNewAccessKeySection }</SectionTitle>
            <Wrapper>
                <TextField
                    label={ Strings.accessKeysPage.newAccessKeyName }
                    variant="standard"
                    value={props.pedningAccessKeyName}
                    sx={{
                        width: '300px'
                    }}
                    onChange={(event) => props.setPendingAccessKey(event.target.value)}
                />
                <div>
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={props.addButtonClicked}
                        disabled={!props.addButtonIsEnabled}
                        sx={{
                            marginLeft: '20px'
                        }}
                    >{ Strings.accessKeysPage.createButtonTitle }</Button>
                </div>
            </Wrapper>
        </>
    )
}