import { Box, Divider } from "@mui/material"
import { useState } from "react";
import styled from "styled-components";
import { BusinessPartnerAccessKey, useAccessKeysLazyQuery, useGenerateAccessKeyMutation, useRevokeAccessKeyMutation } from "../../generated/graphql";
import { Strings } from "../../Strings";
import { AccessKeysData } from "./states/AccessKeysData";
import { AccessKeysError } from "./states/AccessKeysError";
import { AccessKeysHeader } from "./components/AccessKeysHeader";
import { AccessKeysLinks } from "./components/AccessKeysLinks";
import { AccessKeysLoading } from "./states/AccessKeysLoading";
import { AccessKeyCreatedDialog } from "./dialogs/AccessKeyCreatedDialog";
import { RevokeAccessKeyDialog } from "./dialogs/RevokeAccessKeyDialog";

const style = {
    position: 'absolute' as 'absolute',
    overflow: 'scroll',
    maxHeight: '80%',
    top: '0',
    marginTop: '3%',
    marginBottom: '80px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const DescriptionLabel = styled.p`
    margin: 10px 0;
    opacity: 0.8;
`

enum PageState {
    loading,
    data,
    error,
}

export interface AccessKeysPageProps {
    isShown: boolean,
    dismissAction: () => void,
}

export const AccessKeysPage: React.FC<AccessKeysPageProps> = (props: AccessKeysPageProps) => {

    const [state, setState] = useState(PageState.loading);
    const [fetchQuery, { loading, data, refetch, called }] = useAccessKeysLazyQuery();
    const [generateAccessKey, generateAccessKeyMutation] = useGenerateAccessKeyMutation();
    const [revokeAccessKey, revokeAccessKeyMutation] = useRevokeAccessKeyMutation();
    const isLoading = loading || generateAccessKeyMutation.loading || revokeAccessKeyMutation.loading;

    if (isLoading && state !== PageState.loading) {
        setState(PageState.loading);
    } else if (!isLoading && !data?.businessPartner && state !== PageState.error) {
        setState(PageState.error);
    } else if (!isLoading && data?.businessPartner && state !== PageState.data) {
        setState(PageState.data);
    }

    // Data refresh

    function refreshData() {
        if (!called) {
            fetchQuery()
        } else {
            refetch()
        }
        setState(PageState.loading);
    }

    const [wasShown, setWasShown] = useState(false);
    if (!wasShown && props.isShown && !isLoading) {
        refreshData()
    }
    if (wasShown !== props.isShown)
        setWasShown(props.isShown);

    
    // Pending access key

    const [generatedAccessKey, setGeneratedAccessKey] = useState(null as string | null)
    const [pendingAccessKeyName, setPendingAccessKeyName] = useState('');

    function addButtonClicked() {
        generateAccessKey({
            variables: {
                name: pendingAccessKeyName,
            }
        })
        .then(response => {
            const generatedKey = response.data?.generateAccessKey ?? null;
            setGeneratedAccessKey(generatedKey);
            refreshData();
        })
        setPendingAccessKeyName('');
    }


    // Revoke access key

    const [pendingKeyToRemove, setPendingKeyToRemove] = useState(null as BusinessPartnerAccessKey | null);

    function revokeButtonClicked(accessKey: BusinessPartnerAccessKey) {
        setPendingKeyToRemove(accessKey);
    }

    function revokeAccessKeyConfirmed() {
        if (pendingKeyToRemove) {
            revokeAccessKey({
                variables: {
                    accessKeyId: pendingKeyToRemove.id,
                }
            }).finally(refreshData);
            setPendingKeyToRemove(null);
        }
    }


    // Render

    const content = () => {
        switch (state) {
            case PageState.data:
                return <AccessKeysData
                    accessKeys={data?.businessPartner?.accessKeys ?? []}
                    pedningAccessKeyName={pendingAccessKeyName}
                    setPendingAccessKey={setPendingAccessKeyName}
                    addButtonIsEnabled={pendingAccessKeyName.length > 0}
                    addButtonClicked={addButtonClicked}
                    revokeButtonClicked={revokeButtonClicked}
                />
            case PageState.error:
                return <AccessKeysError />
            case PageState.loading:
                return <AccessKeysLoading />
        }
    }

    return (
        <Box sx={style}>
            <AccessKeysHeader
                dismissAction={props.dismissAction}
            />
            <DescriptionLabel>{ Strings.accessKeysPage.description }</DescriptionLabel>
            <AccessKeysLinks />
            <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
            { content() }
            <RevokeAccessKeyDialog
                keyToRevoke={pendingKeyToRemove}
                revokeAction={revokeAccessKeyConfirmed}
                dismissAction={() => setPendingKeyToRemove(null)}
            />
            <AccessKeyCreatedDialog
                partnerAccessKey={generatedAccessKey}
                dismissAction={() => setGeneratedAccessKey(null)}
            />
        </Box>
    )
}