import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BusinessLicense = {
  __typename?: 'BusinessLicense';
  activeFrom?: Maybe<Scalars['String']>;
  activeTo?: Maybe<Scalars['String']>;
  licenseKey: Scalars['String'];
  note: Scalars['String'];
  partnerName: Scalars['String'];
  state: BusinessLicenseState;
};

export enum BusinessLicenseState {
  Active = 'active',
  ClientRevoked = 'clientRevoked',
  PartnerRevoked = 'partnerRevoked',
  WaitingForActivation = 'waitingForActivation'
}

export type BusinessPartner = {
  __typename?: 'BusinessPartner';
  accessKeys: Array<BusinessPartnerAccessKey>;
  activeLicenseCount: Scalars['Float'];
  email: Scalars['String'];
  licenseLimit: Scalars['Float'];
  licenses: Array<BusinessLicense>;
  name: Scalars['String'];
};

export type BusinessPartnerAccessKey = {
  __typename?: 'BusinessPartnerAccessKey';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BusinessPartnerLoginResponse = {
  __typename?: 'BusinessPartnerLoginResponse';
  apiKey: Scalars['String'];
  partner: BusinessPartner;
};

export enum ComponentType {
  BarOrLineChart = 'barOrLineChart',
  Gauge = 'gauge',
  StackedBarLineChart = 'stackedBarLineChart',
  Table = 'table',
  TotalValue = 'totalValue'
}

export type ConnectedAccount = {
  __typename?: 'ConnectedAccount';
  accountKey: Scalars['String'];
  connectedAccountUuid: Scalars['String'];
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type ConnectedAccountInput = {
  accountKey: Scalars['String'];
  connectedAccountUuid: Scalars['String'];
  name: Scalars['String'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  components: Array<DashboardComponent>;
  dashboardUuid: Scalars['String'];
  defaultDatePreset?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type DashboardComponent = {
  __typename?: 'DashboardComponent';
  componentUuid: Scalars['String'];
  configuration: Array<DashboardComponentConfigurationValue>;
  dataTypeIds: Array<Scalars['Float']>;
  type: ComponentType;
};

export type DashboardComponentConfigurationValue = {
  __typename?: 'DashboardComponentConfigurationValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DashboardComponentInput = {
  componentUuid: Scalars['String'];
  configuration: Array<DashboardComponentInputConfigValue>;
  dataTypeIds: Array<Scalars['Int']>;
  type: ComponentType;
};

export type DashboardComponentInputConfigValue = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum FirstWeekDay {
  Monday = 'monday',
  Sunday = 'sunday'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateLicense?: Maybe<BusinessLicense>;
  createDashboard: Dashboard;
  deleteConnectedAccount?: Maybe<ConnectedAccount>;
  deleteDashboard?: Maybe<Dashboard>;
  generateAccessKey?: Maybe<Scalars['String']>;
  generateLicenses?: Maybe<Array<BusinessLicense>>;
  putConnectedAccount: ConnectedAccount;
  putDashboardComponents?: Maybe<Dashboard>;
  putRevenueCatId: RevenueCatSubscription;
  renameDashboard?: Maybe<Dashboard>;
  revokeAccessKey?: Maybe<Scalars['Boolean']>;
  revokeLicenseFromClient?: Maybe<BusinessLicense>;
  revokeLicenseFromPartner?: Maybe<BusinessLicense>;
  setDashboardDefaultDatePreset?: Maybe<Dashboard>;
  setLicenseNote?: Maybe<BusinessLicense>;
  setUserPreferences: UserPreferences;
};


export type MutationActivateLicenseArgs = {
  accountKey: Scalars['String'];
  licenseKey: Scalars['String'];
};


export type MutationCreateDashboardArgs = {
  name: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationDeleteConnectedAccountArgs = {
  connectedAccountUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationDeleteDashboardArgs = {
  dashboardUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationGenerateAccessKeyArgs = {
  name: Scalars['String'];
};


export type MutationGenerateLicensesArgs = {
  numberOfLicenses: Scalars['Float'];
};


export type MutationPutConnectedAccountArgs = {
  connectedAccount: ConnectedAccountInput;
  uid: Scalars['String'];
};


export type MutationPutDashboardComponentsArgs = {
  components: Array<DashboardComponentInput>;
  dashboardUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationPutRevenueCatIdArgs = {
  revenueCatId: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationRenameDashboardArgs = {
  dashboardUuid: Scalars['String'];
  name: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationRevokeAccessKeyArgs = {
  accessKeyId: Scalars['String'];
};


export type MutationRevokeLicenseFromClientArgs = {
  accountKeyHash: Scalars['String'];
  licenseKey: Scalars['String'];
};


export type MutationRevokeLicenseFromPartnerArgs = {
  licenseKey: Scalars['String'];
};


export type MutationSetDashboardDefaultDatePresetArgs = {
  dashboardUuid: Scalars['String'];
  defaultDatePresetId: Scalars['Float'];
  uid: Scalars['String'];
};


export type MutationSetLicenseNoteArgs = {
  licenseKey: Scalars['String'];
  note: Scalars['String'];
};


export type MutationSetUserPreferencesArgs = {
  preferences: UserPreferencesInput;
  uid: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  content: Scalars['String'];
  title: Scalars['String'];
};

export enum NumberFormat {
  Classic = 'classic',
  Us = 'us'
}

export type Query = {
  __typename?: 'Query';
  businessPartner?: Maybe<BusinessPartner>;
  businessPartnerLogin?: Maybe<BusinessPartnerLoginResponse>;
  connectedAccounts: Array<ConnectedAccount>;
  dashboard?: Maybe<Dashboard>;
  dashboards: Array<Dashboard>;
  license?: Maybe<BusinessLicense>;
  news?: Maybe<News>;
  userPreferences?: Maybe<UserPreferences>;
};


export type QueryBusinessPartnerLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryConnectedAccountsArgs = {
  uid: Scalars['String'];
};


export type QueryDashboardArgs = {
  dashboardUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type QueryDashboardsArgs = {
  uid: Scalars['String'];
};


export type QueryLicenseArgs = {
  licenseKey: Scalars['String'];
};


export type QueryNewsArgs = {
  uid: Scalars['String'];
};


export type QueryUserPreferencesArgs = {
  uid: Scalars['String'];
};

export type RevenueCatSubscription = {
  __typename?: 'RevenueCatSubscription';
  revenueCatId: Scalars['String'];
  uid: Scalars['String'];
};

export enum TimeFormat {
  Format12h = 'format12h',
  Format24h = 'format24h'
}

export type UserPreferences = {
  __typename?: 'UserPreferences';
  firstWeekDay: FirstWeekDay;
  numberFormat: NumberFormat;
  timeFormat: TimeFormat;
};

export type UserPreferencesInput = {
  firstWeekDay: FirstWeekDay;
  numberFormat: NumberFormat;
  timeFormat: TimeFormat;
};

export type AccessKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessKeysQuery = { __typename?: 'Query', businessPartner?: { __typename?: 'BusinessPartner', accessKeys: Array<{ __typename?: 'BusinessPartnerAccessKey', id: string, name: string }> } | null };

export type GenerateAccessKeyMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type GenerateAccessKeyMutation = { __typename?: 'Mutation', generateAccessKey?: string | null };

export type GenerateLicensesMutationVariables = Exact<{
  numberOfLicenses: Scalars['Float'];
}>;


export type GenerateLicensesMutation = { __typename?: 'Mutation', generateLicenses?: Array<{ __typename?: 'BusinessLicense', licenseKey: string, state: BusinessLicenseState, partnerName: string, note: string, activeFrom?: string | null, activeTo?: string | null }> | null };

export type LoginQueryVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type LoginQuery = { __typename?: 'Query', businessPartnerLogin?: { __typename?: 'BusinessPartnerLoginResponse', apiKey: string, partner: { __typename?: 'BusinessPartner', licenseLimit: number, activeLicenseCount: number, name: string, email: string } } | null };

export type PartnerFragment = { __typename?: 'BusinessPartner', licenseLimit: number, activeLicenseCount: number, name: string, email: string };

export type PartnerWithLicensesQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerWithLicensesQuery = { __typename?: 'Query', businessPartner?: { __typename?: 'BusinessPartner', licenseLimit: number, activeLicenseCount: number, name: string, email: string, licenses: Array<{ __typename?: 'BusinessLicense', licenseKey: string, state: BusinessLicenseState, partnerName: string, note: string, activeFrom?: string | null, activeTo?: string | null }> } | null };

export type RevokeAccessKeyMutationVariables = Exact<{
  accessKeyId: Scalars['String'];
}>;


export type RevokeAccessKeyMutation = { __typename?: 'Mutation', revokeAccessKey?: boolean | null };

export type RevokeLicenseMutationVariables = Exact<{
  licenseKey: Scalars['String'];
}>;


export type RevokeLicenseMutation = { __typename?: 'Mutation', revokeLicenseFromPartner?: { __typename?: 'BusinessLicense', licenseKey: string, state: BusinessLicenseState, partnerName: string, note: string, activeFrom?: string | null, activeTo?: string | null } | null };

export type SetLicenseNoteMutationVariables = Exact<{
  note: Scalars['String'];
  licenseKey: Scalars['String'];
}>;


export type SetLicenseNoteMutation = { __typename?: 'Mutation', setLicenseNote?: { __typename?: 'BusinessLicense', licenseKey: string, state: BusinessLicenseState, partnerName: string, note: string, activeFrom?: string | null, activeTo?: string | null } | null };

export const PartnerFragmentDoc = gql`
    fragment Partner on BusinessPartner {
  licenseLimit
  activeLicenseCount
  name
  email
}
    `;
export const AccessKeysDocument = gql`
    query AccessKeys {
  businessPartner {
    accessKeys {
      id
      name
    }
  }
}
    `;

/**
 * __useAccessKeysQuery__
 *
 * To run a query within a React component, call `useAccessKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessKeysQuery(baseOptions?: Apollo.QueryHookOptions<AccessKeysQuery, AccessKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessKeysQuery, AccessKeysQueryVariables>(AccessKeysDocument, options);
      }
export function useAccessKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessKeysQuery, AccessKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessKeysQuery, AccessKeysQueryVariables>(AccessKeysDocument, options);
        }
export type AccessKeysQueryHookResult = ReturnType<typeof useAccessKeysQuery>;
export type AccessKeysLazyQueryHookResult = ReturnType<typeof useAccessKeysLazyQuery>;
export type AccessKeysQueryResult = Apollo.QueryResult<AccessKeysQuery, AccessKeysQueryVariables>;
export const GenerateAccessKeyDocument = gql`
    mutation GenerateAccessKey($name: String!) {
  generateAccessKey(name: $name)
}
    `;
export type GenerateAccessKeyMutationFn = Apollo.MutationFunction<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>;

/**
 * __useGenerateAccessKeyMutation__
 *
 * To run a mutation, you first call `useGenerateAccessKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAccessKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAccessKeyMutation, { data, loading, error }] = useGenerateAccessKeyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGenerateAccessKeyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>(GenerateAccessKeyDocument, options);
      }
export type GenerateAccessKeyMutationHookResult = ReturnType<typeof useGenerateAccessKeyMutation>;
export type GenerateAccessKeyMutationResult = Apollo.MutationResult<GenerateAccessKeyMutation>;
export type GenerateAccessKeyMutationOptions = Apollo.BaseMutationOptions<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>;
export const GenerateLicensesDocument = gql`
    mutation GenerateLicenses($numberOfLicenses: Float!) {
  generateLicenses(numberOfLicenses: $numberOfLicenses) {
    licenseKey
    state
    partnerName
    note
    activeFrom
    activeTo
  }
}
    `;
export type GenerateLicensesMutationFn = Apollo.MutationFunction<GenerateLicensesMutation, GenerateLicensesMutationVariables>;

/**
 * __useGenerateLicensesMutation__
 *
 * To run a mutation, you first call `useGenerateLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLicensesMutation, { data, loading, error }] = useGenerateLicensesMutation({
 *   variables: {
 *      numberOfLicenses: // value for 'numberOfLicenses'
 *   },
 * });
 */
export function useGenerateLicensesMutation(baseOptions?: Apollo.MutationHookOptions<GenerateLicensesMutation, GenerateLicensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateLicensesMutation, GenerateLicensesMutationVariables>(GenerateLicensesDocument, options);
      }
export type GenerateLicensesMutationHookResult = ReturnType<typeof useGenerateLicensesMutation>;
export type GenerateLicensesMutationResult = Apollo.MutationResult<GenerateLicensesMutation>;
export type GenerateLicensesMutationOptions = Apollo.BaseMutationOptions<GenerateLicensesMutation, GenerateLicensesMutationVariables>;
export const LoginDocument = gql`
    query Login($password: String!, $email: String!) {
  businessPartnerLogin(password: $password, email: $email) {
    apiKey
    partner {
      ...Partner
    }
  }
}
    ${PartnerFragmentDoc}`;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const PartnerWithLicensesDocument = gql`
    query PartnerWithLicenses {
  businessPartner {
    ...Partner
    licenses {
      licenseKey
      state
      partnerName
      note
      activeFrom
      activeTo
    }
  }
}
    ${PartnerFragmentDoc}`;

/**
 * __usePartnerWithLicensesQuery__
 *
 * To run a query within a React component, call `usePartnerWithLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerWithLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerWithLicensesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerWithLicensesQuery(baseOptions?: Apollo.QueryHookOptions<PartnerWithLicensesQuery, PartnerWithLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerWithLicensesQuery, PartnerWithLicensesQueryVariables>(PartnerWithLicensesDocument, options);
      }
export function usePartnerWithLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerWithLicensesQuery, PartnerWithLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerWithLicensesQuery, PartnerWithLicensesQueryVariables>(PartnerWithLicensesDocument, options);
        }
export type PartnerWithLicensesQueryHookResult = ReturnType<typeof usePartnerWithLicensesQuery>;
export type PartnerWithLicensesLazyQueryHookResult = ReturnType<typeof usePartnerWithLicensesLazyQuery>;
export type PartnerWithLicensesQueryResult = Apollo.QueryResult<PartnerWithLicensesQuery, PartnerWithLicensesQueryVariables>;
export const RevokeAccessKeyDocument = gql`
    mutation RevokeAccessKey($accessKeyId: String!) {
  revokeAccessKey(accessKeyId: $accessKeyId)
}
    `;
export type RevokeAccessKeyMutationFn = Apollo.MutationFunction<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>;

/**
 * __useRevokeAccessKeyMutation__
 *
 * To run a mutation, you first call `useRevokeAccessKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessKeyMutation, { data, loading, error }] = useRevokeAccessKeyMutation({
 *   variables: {
 *      accessKeyId: // value for 'accessKeyId'
 *   },
 * });
 */
export function useRevokeAccessKeyMutation(baseOptions?: Apollo.MutationHookOptions<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>(RevokeAccessKeyDocument, options);
      }
export type RevokeAccessKeyMutationHookResult = ReturnType<typeof useRevokeAccessKeyMutation>;
export type RevokeAccessKeyMutationResult = Apollo.MutationResult<RevokeAccessKeyMutation>;
export type RevokeAccessKeyMutationOptions = Apollo.BaseMutationOptions<RevokeAccessKeyMutation, RevokeAccessKeyMutationVariables>;
export const RevokeLicenseDocument = gql`
    mutation RevokeLicense($licenseKey: String!) {
  revokeLicenseFromPartner(licenseKey: $licenseKey) {
    licenseKey
    state
    partnerName
    note
    activeFrom
    activeTo
  }
}
    `;
export type RevokeLicenseMutationFn = Apollo.MutationFunction<RevokeLicenseMutation, RevokeLicenseMutationVariables>;

/**
 * __useRevokeLicenseMutation__
 *
 * To run a mutation, you first call `useRevokeLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeLicenseMutation, { data, loading, error }] = useRevokeLicenseMutation({
 *   variables: {
 *      licenseKey: // value for 'licenseKey'
 *   },
 * });
 */
export function useRevokeLicenseMutation(baseOptions?: Apollo.MutationHookOptions<RevokeLicenseMutation, RevokeLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeLicenseMutation, RevokeLicenseMutationVariables>(RevokeLicenseDocument, options);
      }
export type RevokeLicenseMutationHookResult = ReturnType<typeof useRevokeLicenseMutation>;
export type RevokeLicenseMutationResult = Apollo.MutationResult<RevokeLicenseMutation>;
export type RevokeLicenseMutationOptions = Apollo.BaseMutationOptions<RevokeLicenseMutation, RevokeLicenseMutationVariables>;
export const SetLicenseNoteDocument = gql`
    mutation SetLicenseNote($note: String!, $licenseKey: String!) {
  setLicenseNote(note: $note, licenseKey: $licenseKey) {
    licenseKey
    state
    partnerName
    note
    activeFrom
    activeTo
  }
}
    `;
export type SetLicenseNoteMutationFn = Apollo.MutationFunction<SetLicenseNoteMutation, SetLicenseNoteMutationVariables>;

/**
 * __useSetLicenseNoteMutation__
 *
 * To run a mutation, you first call `useSetLicenseNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLicenseNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLicenseNoteMutation, { data, loading, error }] = useSetLicenseNoteMutation({
 *   variables: {
 *      note: // value for 'note'
 *      licenseKey: // value for 'licenseKey'
 *   },
 * });
 */
export function useSetLicenseNoteMutation(baseOptions?: Apollo.MutationHookOptions<SetLicenseNoteMutation, SetLicenseNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLicenseNoteMutation, SetLicenseNoteMutationVariables>(SetLicenseNoteDocument, options);
      }
export type SetLicenseNoteMutationHookResult = ReturnType<typeof useSetLicenseNoteMutation>;
export type SetLicenseNoteMutationResult = Apollo.MutationResult<SetLicenseNoteMutation>;
export type SetLicenseNoteMutationOptions = Apollo.BaseMutationOptions<SetLicenseNoteMutation, SetLicenseNoteMutationVariables>;