import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import moment from "moment"
import { BusinessLicense, BusinessLicenseState, Maybe } from "../../../generated/graphql"
import { Strings } from "../../../Strings"
import EditIcon from '@mui/icons-material/Edit';
import { RevokeButton } from "../../../components/styled/RevokeButton"

export interface LicenseListProps {
    licenses: BusinessLicense[],
    revokeButtonClicked: (license: BusinessLicense) => void,
    noteButtonClicked: (license: BusinessLicense) => void,
}

const stateOrder = [
    BusinessLicenseState.Active,
    BusinessLicenseState.WaitingForActivation,
    BusinessLicenseState.PartnerRevoked,
    BusinessLicenseState.ClientRevoked,
]

export const LicenseList: React.FC<LicenseListProps> = (props: LicenseListProps) => {
    const sortedLicenses = [...props.licenses]
        .sort((a,b) => {
            if (a.state === b.state) {
                return 0
            } else {
                return stateOrder.indexOf(a.state) < stateOrder.indexOf(b.state)
                    ? -1
                    : 1
            }
        });
    
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>License key</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Note</TableCell>
                        <TableCell>Active from</TableCell>
                        <TableCell>Revoked</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { sortedLicenses.map((license) => (
                        <TableRow
                            key={license.licenseKey}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{license.licenseKey}</TableCell>
                            <TableCell sx={{ color: licenseStateTextColor(license.state) }}>{ localizedLicenseState(license.state) }</TableCell>
                            <TableCell>
                                {license.note}
                                <IconButton
                                    size="small"
                                    sx={{ opacity: '0.5' }}
                                    onClick={ () => props.noteButtonClicked(license) }
                                >
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>{ formatDate(license.activeFrom) }</TableCell>
                            <TableCell>{ formatDate(license.activeTo) }</TableCell>
                            <TableCell>
                                { !(license.state === BusinessLicenseState.ClientRevoked || license.state === BusinessLicenseState.PartnerRevoked) &&
                                    <RevokeButton
                                        onClick={() => props.revokeButtonClicked(license)}
                                    >Revoke</RevokeButton>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function localizedLicenseState(state: BusinessLicenseState): string {
    switch (state) {
        case BusinessLicenseState.Active:
            return Strings.dashboardPage.licenseStates.active
        case BusinessLicenseState.WaitingForActivation:
            return Strings.dashboardPage.licenseStates.waitingForActivation
        case BusinessLicenseState.ClientRevoked:
            return Strings.dashboardPage.licenseStates.clientRevoked
        case BusinessLicenseState.PartnerRevoked:
            return Strings.dashboardPage.licenseStates.partnerRevoked
    }
}

function licenseStateTextColor(state: BusinessLicenseState): string {
    switch (state) {
        case BusinessLicenseState.Active:
            return '#1b5e20'
        case BusinessLicenseState.WaitingForActivation:
            return '#212121'
        case BusinessLicenseState.ClientRevoked:
            return '#b71c1c'
        case BusinessLicenseState.PartnerRevoked:
            return '#b71c1c'
    }
}

function formatDate(date: Maybe<string> | undefined): string {
    if (!date) {
        return '';
    }
    
    return moment(date).format('lll');
}