export const Strings = {
    common: {
        ok: 'OK',
        cancel: 'Cancel',
    },
    loginPage: {
        email: 'Email',
        password: 'Password',
        loginButton: 'Log in',
        loggingInButton: 'Logging in',
        errors: {
            missingEmailOrPassword: {
                title: 'Missing email or password',
                message: 'Please fill in the missing value.'
            },
            incorrectCredentials: {
                title: 'Incorrect credentials',
                message: 'Please check your email and password and try it again.'
            },
        },
    },
    dashboardPage: {
        loadingFailed: {
            title: 'Upps, something went wrong.',
            message: 'We are sorry. Please try it later.',
        },
        licenseStates: {
            active: 'Active',
            clientRevoked: 'Client revoked',
            partnerRevoked: 'Partner revoked',
            waitingForActivation: 'Not activated',
        },
        availableLicensesIndicator: {
            title: 'Non revoked licenses:',
        },
        generateLicenseButton: 'Generate license(s)',
        accessKeysButton: 'Access keys',
        generateLicenseDialog: {
            title: 'Generate license(s)',
            numberOfLicenses: 'Number of licenses to generate',
            generateButton: 'Generate',
        },
        errors: {
            invalidLicenseCount: {
                title: 'Invalid number of licenses',
                message: 'Please enter a valid number of licenses and try it again.'
            },
            insufficientLicenseLimit: {
                title: 'Insufficient license limit',
                message: 'You don\'t have enought non revoked licenses. Please reach out at hello@healthexport.app to increase the limit.'
            },
        },
        revokeLicenseDialog: {
            title: 'Revoke license',
            message: (licenseKey: string) => `Are you sure you want to revoke license (license key ${licenseKey})? This is an irreversible action.`,
            revokeButton: 'Revoke',
        },
        clientNoteDialog: {
            title: 'Note',
            placeholder: 'Note',
            saveButton: 'Save',
        }
    },
    accessKeysPage: {
        title: 'Access keys',
        description: 'You can use access keys to access client\'s health data via API or HealthExport Remote website.',
        linkButtons: {
            apiDocs: 'Api documentation',
            remoteWebsite: 'Remote website'
        },
        accessKeysSection: 'Existing access keys:',
        createNewAccessKeySection: 'Create a new access key:',
        newAccessKeyName: 'Name',
        createButtonTitle: 'Create',
        accessKeyName: 'Name',
        revokeButton: 'Revoke',
        error: 'Something went wrong. Please try it later.',
        revokeKeyDialog: {
            title: 'Revoke access key',
            message: (name: string) => `Are you sure you want to revoke access key ${name}? This is an irreversible action.`,
            revokeButton: 'Revoke',
        },
        keyCreatedDialog: {
            title: 'Access key created',
            message: (accessKey: string) => `Your access key is: ${accessKey}`,
        },
    }
}