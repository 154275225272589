import { CircularProgress } from "@mui/material"
import styled from "styled-components"

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const AccessKeysLoading: React.FC = () => {
    return (
        <Wrapper>
            <CircularProgress />
        </Wrapper>
    )
}